import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {List} from 'components/List';
import 'keen-slider/keen-slider.min.css';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {PageProps, graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

import styled from 'styled-components';
import React, {useState} from 'react';
import Bold from 'components/Bold';
import IndexBackground from 'components/monei-pay/IndexBackground';
import {InternalPageLink} from 'components/links/Pages';
import CardsContainer from 'components/CardsContainer';
import {Card} from 'components/Card';
import cards from 'images/credit-cards.svg';
import cart2 from 'images/cart2.svg';
import mobile_payment from 'images/mobile_payment.svg';
import coins from 'images/coins.svg';
import security from 'images/security.svg';
import {Background} from 'components/Background';
import {MainTitleContent} from 'components/monei-pay/MainTitleContent';
import yumminn from 'images/yumminn_logo_full.png';
import nuveapp from 'images/logo-nuve-app.png';
import hotelgest from 'images/logo-hotelgest.png';
import glop from 'images/glop_logo.svg';
import honei from 'images/honei_logo.png';
import qamarero from 'images/qamarero_logo.png';
import revoLogo from 'images/revo_logo.png';
import yumminnCaseStudy from 'images/yummin_case_study.png';
import modular from 'images/modular.svg';
import {ButtonLink} from 'components/Button';
import check from 'images/check.svg';
import integration from 'images/integration.svg';
import {PaymentMethods} from 'components/PaymentMethods';
import {StyledImg} from 'components/StyledSx';
import {BlogLink} from 'components/links/Blog';
import {SupportLink} from 'components/links/Support';
import {AnchorLink} from 'components/AnchorLink';
import {GradientBox} from 'components/landings/GradientBox';
import {ScreenSizes} from '../../../types/responsive';
import {FaqsSection} from 'components/FaqsSection';
import integrated_payments_header_image from 'images/integrated_payments_header_image.png';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {docco} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {TrustedBy} from 'components/TrustedBy';
import {GoogleReviews, Place} from 'components/GoogleReviews';

const CardWrapper = styled.div`
  max-width: 255px;
`;

const CardImage = styled.img`
  position: absolute;
`;
const CheckGreen = styled.img`
  margin: 4px 8px 0 0;
  svg {
    fill: white;
  }
`;
export const partnersLogos = [
  {
    logo: yumminn,
    url: {
      es: 'https://yumminn.com/'
    },
    alt: 'Yumminn integration',
    width: 200,
    scale: 1
  },
  {
    logo: glop,
    url: {
      es: 'https://www.glop.es/'
    },
    alt: 'Glop integration',
    width: 90,
    scale: 1
  },
  {
    logo: honei,
    url: {
      es: 'https://honei.app/'
    },
    alt: 'Honei',
    width: 180,
    scale: 1
  },
  {
    logo: qamarero,
    url: {
      es: 'https://www.qamarero.com/'
    },
    alt: 'Qamarero',
    width: 200,
    scale: 1
  },
  {
    logo: revoLogo,
    url: {
      es: 'https://revo.works/'
    },
    alt: 'Revo',
    width: 200,
    scale: 1
  },
  {
    logo: nuveapp,
    url: {
      es: 'https://thenuveapp.com/'
    },
    alt: 'Nuve App',
    width: 135,
    scale: 1
  },
  {
    logo: hotelgest,
    url: {
      es: 'https://hotelgest.com/'
    },
    alt: 'Hotelgest',
    width: 200,
    scale: 1
  }
];

const SectionDescription = styled.p`
  font-weight: 500;
  color: #fafafa;
  font-size: 16px;
  p {
    font-size: 1.25rem;
  }
  a {
    color: #ffffff;
    text-decoration: underline;
  }
`;

const CodeSnippet = styled(SyntaxHighlighter)`
  border-radius: 4px;
  padding: 1.5em !important;
  @media (max-width: ${ScreenSizes.md}) {
    margin-top: 2em;
  }
`;

export const IndexImage = styled(StyledImg)`
  position: absolute;
  left: 60%;
  top: 70px;
  width: 400px;
  z-index: 10;
  mask-image: linear-gradient(180deg, #000000 94%, rgba(0, 0, 0, 0) 100%);

  @media (max-width: ${ScreenSizes.lg}) {
    display: none;
  }

  @media (max-width: ${ScreenSizes.sm}) {
    position: initial;
  }
`;

const CtaImage = styled.img`
  position: absolute;
  bottom: -6px;
  right: 98px;
  width: 19.5%;

  @media (max-width: ${ScreenSizes.lg}) {
    bottom: 0;
    width: 240px;
    right: 60px;
  }
  @media (max-width: ${ScreenSizes.md}) {
    display: none;
  }
`;

const PaymentCard = styled.div`
  padding: 24px 48px;
  flex-basis: 100%;

  @media (max-width: 768px) {
    padding: 40px 32px;
  }
`;

const ReviewsBackground = styled.div`
  position: relative;
  margin-top: 100px;

  &:before {
    display: block;
    content: '';
    z-index: -1;
    height: 70%;
    position: absolute;
    transform: skew(0, -9deg);
    left: 0;
    right: 0;
    bottom: 0;
    top: 20%;
    background: linear-gradient(96.34deg, #00e9d5 0%, #aab4e9 100%);
  }
`;

type SnippetType = 'javascript' | 'php' | 'python';

const Connect: React.FC<PageProps> = (props) => {
  const {t} = useI18next();
  const [codeSnippet, setCodeSnippet] = useState<SnippetType>('javascript');
  const {data} = props;
  const place: Place = {
    rating: data.googlePlacesPlace.rating,
    user_ratings_total: data.googlePlacesPlace.user_ratings_total,
    reviews: data.allGooglePlacesReview.nodes
  };

  const javascriptCodeSnippet = `const {Monei, PaymentTransactionType} = require('@monei-js/node-sdk');
const monei = new Monei('pk_test_36cf3e8a15eff3f5be983562ea6b13ec');
monei.payments.create({
  amount: 110, // 1.10 EUR
  currency: 'EUR',
  orderId: '14379133960355',
  paymentToken: '7cc38b08ff471ccd313ad62b23b9f362b107560b',
  callbackUrl: 'https://example.com/checkout/callback',
  completeUrl: 'https://example.com/checkout/complete',
  customer: {
    name: 'John Doe',
    email: 'example@example.com',
    phone: '+49123456789'
  }
});
`;
  const phpCodeSnippet = `<?php
$monei = new Monei\\MoneiClient('pk_test_36cf3e8a15eff3f5be983562ea6b13ec');
$monei->payments->create([
    'amount' => 110, // 1.10 EUR
    'orderId' => '14379133960355',
    'currency' => 'EUR',
    'payment_token' => '7cc38b08ff471ccd313ad62b23b9f362b107560b',
    'callback_url' => 'https://example.com/checkout/callback',
    'complete_url' => 'https://example.com/checkout/complete'
]);
`;
  const pythonCodeSnippet = `import Monei
from Monei import CreatePaymentRequest

monei = Monei.MoneiClient(api_key="pk_test_36cf3e8a15eff3f5be983562ea6b13ec")
monei.Payments.create(CreatePaymentRequest(
    amount=110,
    currency="EUR",
    order_id="14379133960355",
    payment_token="7cc38b08ff471ccd313ad62b23b9f362b107560b",
    callback_url="https://example.com/checkout/callback",
    complete_url="https://example.com/checkout/complete"
))
`;
  const snippets: Record<SnippetType, string> = {
    javascript: javascriptCodeSnippet,
    php: phpCodeSnippet,
    python: pythonCodeSnippet
  };

  const faqs = [
    {
      header: t('What are integrated payments?'),
      text: t(`
          Integrated payment solutions help streamline and automate the payment acceptance
          process. It’s a payment system that communicates with the other software your
          business uses, making the payment integration for each platform or marketplace
          unique.`),
      content: (
        <Trans parent="p">
          Integrated payment solutions help streamline and automate the payment acceptance process.
          It’s a payment system that communicates with the other software your business uses, making
          the payment integration for each platform or marketplace unique.
        </Trans>
      )
    },
    {
      header: t(
        'What are the main benefits of integrated payment solutions for platforms and marketplaces via MONEI Connect?'
      ),
      text: t(
        "- Save time and money developing your own payments solution. - Earn a revenue share. - Leverage MONEI's billing system to charge your users/merchants for additional services. - Skip dealing with payment compliance. - Help your users accept more payment methods. - Make fewer errors."
      ),
      content: (
        <List sx={{marginTop: '12px'}}>
          <Trans parent="li">Save time and money developing your own payments solution</Trans>
          <Trans parent="li">Earn a revenue share</Trans>
          <Trans parent="li">
            Leverage MONEI's billing system to charge your users/merchants for additional services
          </Trans>
          <Trans parent="li">Skip dealing with payment compliance</Trans>
          <Trans parent="li">Help your users accept more payment methods</Trans>
          <Trans parent="li">Make fewer errors</Trans>
        </List>
      )
    },
    {
      header: t('What’s an example of integrated payments?'),
      test: t(`Yumminn is a platform in Spain that helps restaurants accept orders and payments
          via QR codes. By using MONEI Connect as an integrated payments partner, Yumminn
          can let its users accept all the payment methods MONEI supports without having
          to deal with the development, compliance, and KYC. It’s all handled by MONEI.
          More platforms that use MONEI Connect for integrated payments are Honei, Glop,
          Qamarero, Nuve, and Taxitronic.`),
      content: (
        <Trans parent="p">
          Yumminn is a platform in Spain that helps restaurants accept orders and payments via QR
          codes. By using MONEI Connect as an integrated payments partner, Yumminn can let its users
          accept all the payment methods MONEI supports without having to deal with the development,
          compliance, and KYC. It’s all handled by MONEI. More platforms that use MONEI Connect for
          integrated payments are Honei, Glop, Qamarero, Nuve, and Taxitronic.
        </Trans>
      )
    },
    {
      header: t(
        'How long does it take to integrate an integrated payment solution into my platform or marketplace?'
      ),
      text: t(`The time required for integration depends on various factors, including the
          complexity of your platform, the chosen payment solution, and the availability
          of developer resources. Generally, well-documented APIs and developer-friendly
          tools provided by integrated payment solutions (like MONEI Connect) can expedite
          the integration process.`),
      content: (
        <Trans parent="p">
          The time required for integration depends on various factors, including the complexity of
          your platform, the chosen payment solution, and the availability of developer resources.
          Generally, well-documented APIs and developer-friendly tools provided by integrated
          payment solutions (like MONEI Connect) can expedite the integration process.
        </Trans>
      )
    },
    {
      header: t(
        'Are there any compliance requirements or security considerations I should be aware of?'
      ),
      text: t(`Absolutely. Compliance requirements and security considerations are crucial when
          implementing an integrated payment solution. Make sure the solution adheres to
          relevant industry standards, such as PCI-DSS (Payment Card Industry Data
          Security Standard), to protect sensitive customer payment data.`),
      content: (
        <Trans parent="p">
          Absolutely. Compliance requirements and security considerations are crucial when
          implementing an integrated payment solution. Make sure the solution adheres to relevant
          industry standards, such as PCI-DSS (Payment Card Industry Data Security Standard), to
          protect sensitive customer payment data.
        </Trans>
      )
    },
    {
      header: t(
        'What kind of customer support or technical assistance is provided with integrated payment solutions?'
      ),
      text: t(`At MONEI, we provide customer support and technical assistance to help you  
          during the integration process and resolve any issues that may arise. This can  
          include documentation, developer resources, dedicated support channels, and  
          sometimes even on-site assistance depending on the specific service level  
          agreement (SLA) in place. We also handle KYC, onboarding, and payment security  
          and compliance for you.`),
      content: (
        <Trans parent="p">
          At MONEI, we provide customer support and technical assistance to help you during the
          integration process and resolve any issues that may arise. This can include documentation,
          developer resources, dedicated support channels, and sometimes even on-site assistance
          depending on the specific service level agreement (SLA) in place. We also handle KYC,
          onboarding, and payment security and compliance for you.
        </Trans>
      )
    }
  ];

  const schemaFaqs = {
    '@type': 'FAQPage',
    mainEntity: faqs.map((faq) => ({
      '@type': 'Question',
      name: faq.header,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.text
      }
    }))
  };
  return (
    <>
      <SEO
        title={t('Integrated Payment Solutions for Platforms and Marketplaces')}
        description={t(
          'Looking for an integrated payment partner for your platform or marketplace? Build payment experiences without the high development costs. Get MONEI Connect ››'
        )}
        schema={schemaFaqs}
      />

      <IndexBackground>
        <Content>
          <Section columnLg sx={{paddingBottom: '0'}}>
            <MainTitleContent sx={{maxWidth: {all: '590px', lg: '100%'}}}>
              <SectionHeader underline tagName="h1">
                <Trans>Integrated payment solutions for your platform or marketplace</Trans>
              </SectionHeader>
              <Trans parent="p">
                Quickly and easily integrate payments into your business platform or marketplace
                with <Bold>MONEI Connect</Bold>. Build and scale end-to-end payment experiences with
                a simple payment partner API. Save time and money with built-in onboarding,
                fully-managed KYC, and payments compliance and security. Get the best integrated
                payment solution for platforms and marketplaces — join MONEI.
              </Trans>
              <SectionActions>
                <ContactSalesButton variant="light" />
              </SectionActions>
            </MainTitleContent>
            <IndexImage
              src={integrated_payments_header_image}
              alt="Integrated payments"
              title="Integrated payments"
            />
          </Section>
          <TrustedBy clientList={partnersLogos} />
        </Content>
      </IndexBackground>
      <Content>
        <Section centered column sx={{textAlign: 'center'}}>
          <SectionHeader underline style={{textAlign: 'center'}}>
            <Trans>Seamlessly let your users accept payments</Trans>
          </SectionHeader>
          <p style={{textAlign: 'center'}}>
            <Trans>
              Whether your users sell online or in person, quickly integrate with MONEI Connect to
              help them accept{' '}
              <InternalPageLink slug="payment-methods">more payment methods</InternalPageLink>.
            </Trans>
          </p>
          <CardsContainer sx={{marginTop: '100px', marginBottom: '40px'}}>
            <Card padding="80px 24px 32px 24px">
              <CardImage src={mobile_payment} alt="" style={{top: -45, width: 110}} />
              <CardWrapper>
                <p style={{marginBottom: '12px'}}>
                  <Trans>
                    <Bold>Easily onboard and payout users</Bold>
                  </Trans>
                </p>

                <Trans>
                  MONEI handles the{' '}
                  <BlogLink slug="monei-connect-integrated-payments-kyc">
                    Know Your Customer (KYC)
                  </BlogLink>{' '}
                  and payout processes so you can onboard more users, faster, and spend time on
                  sales instead of settlements
                </Trans>
              </CardWrapper>
            </Card>
            <Card padding="80px 32px 32px 32px">
              <CardImage src={cards} alt="" style={{top: -40, width: 80}} />
              <CardWrapper>
                <p style={{marginBottom: '12px'}}>
                  <Trans parent="p">
                    <Bold>Flexible out-of-the-box solution</Bold>
                  </Trans>
                </p>
                <Trans>
                  Save time and money on development by integrating with a single payment system
                  that’s built to scale — add payments to your platform/marketplace or white label
                  our payment solution and charge a fee
                </Trans>
              </CardWrapper>
            </Card>
            <Card padding="80px 32px 32px 32px">
              <CardImage src={security} alt="" style={{top: -48}} />
              <CardWrapper>
                <p style={{marginBottom: '12px'}}>
                  <Trans parent="p">
                    <Bold>Built in payment security</Bold>
                  </Trans>
                </p>
                <Trans>
                  Don’t worry about fraud and transaction security issues, MONEI is{' '}
                  <InternalPageLink slug="pci-dss">PCI DSS Level 1</InternalPageLink> compliant —
                  we’ll take care of payment security for you
                </Trans>
              </CardWrapper>
            </Card>
          </CardsContainer>
          <ContactSalesButton variant="light" />
        </Section>
      </Content>
      <Background>
        <Content>
          <Section centered reverseOnMobile columnMd sx={{paddingTop: {sm: '100px'}}}>
            <CodeSnippet language="javascript" style={docco}>
              {snippets[codeSnippet]}
            </CodeSnippet>
            <div>
              <SectionHeader>
                <Trans>Use the Payment Partner Integration API</Trans>
              </SectionHeader>
              <List className="flex-list" listStyle="none">
                <Trans parent="li">
                  <CheckGreen src={check} width={16} height={16} />︎ Access our APIs on behalf of
                  each MONEI account bound to your partner account.
                </Trans>
                <Trans parent="li">
                  <CheckGreen src={check} width={16} height={16} />︎ Get a unique registration link
                  to let your users register their MONEI account (they’ll always be bund to your
                  partner account).
                </Trans>
                <Trans parent="li">
                  <CheckGreen src={check} width={16} height={16} />︎ View the Partner Dashboard to
                  see payment history, aggregated analytics, and details about each MONEI account
                  bound to your partner account.
                </Trans>
              </List>
              <SectionActions>
                <ButtonLink href="https://docs.monei.com/docs/monei-connect/" variant="dark">
                  <Trans>Go to Docs</Trans>
                </ButtonLink>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <Content>
        <Section column>
          <SectionHeader underline sx={{textAlign: 'center'}}>
            <Trans>Help your users grow their businesses</Trans>
          </SectionHeader>
          <CardsContainer sx={{margin: '40px 0'}}>
            <Card padding="80px 24px 32px 24px">
              <CardImage src={modular} alt="" style={{top: -45, width: 105}} />
              <CardWrapper>
                <p style={{marginBottom: '12px'}}>
                  <Trans parent="p">
                    <Bold>Accept more payment methods</Bold>
                  </Trans>
                </p>
                <Trans>
                  Help your platform or marketplace users accept the widest range of payment methods
                  from{' '}
                  <InternalPageLink slug="payment-methods/credit-cards">
                    credit cards
                  </InternalPageLink>{' '}
                  to <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>
                  ,{' '}
                  <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,{' '}
                  <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink>, and more{' '}
                  <BlogLink slug="local-payment-methods">local payment methods</BlogLink>
                </Trans>
              </CardWrapper>
            </Card>
            <Card padding="80px 32px 32px 32px">
              <CardImage src={coins} alt="" style={{top: -50, width: 80}} />
              <CardWrapper>
                <p style={{marginBottom: '12px'}}>
                  <Trans parent="p">
                    <Bold>Save money</Bold>
                  </Trans>
                </p>
                <Trans>
                  As your users sell more, their transaction{' '}
                  <InternalPageLink slug="pricing">fees will decrease</InternalPageLink> in
                  real-time and for in-person payments,{' '}
                  <InternalPageLink slug="monei-pay">MONEI Pay</InternalPageLink> is 50% cheaper
                  than a traditional POS
                </Trans>
              </CardWrapper>
            </Card>
            <Card padding="80px 32px 32px 32px">
              <CardImage src={cart2} alt="" style={{top: -48, width: 110}} />
              <CardWrapper>
                <p style={{marginBottom: '12px'}}>
                  <Trans parent="p">
                    <Bold>Take online and physical payments</Bold>
                  </Trans>
                </p>
                <Trans>
                  Let users accept payments online via a{' '}
                  <AnchorLink href="https://docs.monei.com/docs/integrations/use-prebuilt-payment-page/">
                    customizable payment page
                  </AnchorLink>{' '}
                  and in person from their phone or tablet, using{' '}
                  <SupportLink slug="articles/4417709618193">QR codes</SupportLink> or{' '}
                  <SupportLink slug="articles/12253203762449">payment requests</SupportLink>
                </Trans>
              </CardWrapper>
            </Card>
          </CardsContainer>
        </Section>
      </Content>
      <Content sx={{maxWidth: '1440px', textAlign: 'center'}}>
        <PaymentCard>
          <PaymentMethods />
          <SectionActions>
            <ButtonLink href="/payment-methods" variant="dark">
              <Trans>Vew all payment methods</Trans>
            </ButtonLink>
          </SectionActions>
        </PaymentCard>
      </Content>
      <ReviewsBackground>
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <SectionHeader underline sx={{textAlign: 'center'}}>
            <Trans>What MONEI merchants are saying</Trans>
          </SectionHeader>
        </div>
        <GoogleReviews place={place} />
      </ReviewsBackground>
      <Background sx={{marginTop: '150px', marginBottom: '190px'}}>
        <Content>
          <Section sx={{paddingBottom: '20px', paddingTop: {sm: '90px'}, alignItems: 'center'}}>
            <div>
              <SectionHeader>
                <Trans>
                  Case study: Yumminn increased contactless payments by 17% with MONEI Connect
                </Trans>
              </SectionHeader>
              <Trans parent="p">
                Yumminn’s hospitality platfrom is the easiest and fastest way for restaurants, bars,
                and hotels in Spain to take orders and accept payments, but without including Bizum
                in its payment method offering, it was hard to meet the needs of Yumminn’s users and
                their end customers. All this changed after switching to MONEI Connect for its
                integrated payments.{' '}
              </Trans>
              <ButtonLink href="/blog/yumminn/" variant="dark">
                <Trans>Read the Case Study</Trans>
              </ButtonLink>
            </div>
            <SectionImage
              src={yumminnCaseStudy}
              height={480}
              mobileWidth={400}
              style={{width: 'auto'}}
              className="hide-on-mobile"
            />
          </Section>
        </Content>
      </Background>
      <Content>
        <GradientBox wrapperStyle={{overflow: 'visible', marginTop: '60px'}}>
          <SectionHeader tagName="h3" underline style={{color: 'white'}}>
            <Trans>
              Get started today with the best integrated payments solution for your platform or
              marketplace
            </Trans>
          </SectionHeader>
          <SectionDescription>
            <Trans parent="p">
              Contact sales now to tell us more about your business and payment needs.
            </Trans>
          </SectionDescription>
          <SectionActions>
            <ContactSalesButton variant="dark" />
          </SectionActions>
          <CtaImage src={integration} />
        </GradientBox>
      </Content>
      <Content>
        <Section centered column>
          <FaqsSection faqs={faqs} title={<Trans>Integrated payment solutions FAQ</Trans>} />
        </Section>
      </Content>
    </>
  );
};

export default Connect;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "integrated-payments-partners"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    googlePlacesPlace {
      user_ratings_total
      rating
    }
    allGooglePlacesReview(sort: {fields: rating, order: DESC}, filter: {rating: {gt: 2}}) {
      nodes {
        id
        rating
        text
        relative_time_description
        profile_photo_url
        author_name
      }
    }
  }
`;
