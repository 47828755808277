import React from 'react';

// Clients
import {Sx} from '../../types/responsive';
import {Client, Clients, defaultClientList} from './Clients';
import {Trans} from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';

const Title = styled.div`
  color: #74858e;
  font-size: 1.2rem;
  margin-bottom: 0;
  margin-top: 3rem;
`;

type Props = {
  clientList?: Client[];
};

export const TrustedBy: React.FC<Props & React.HTMLAttributes<any> & {sx?: Sx}> = ({
  clientList = defaultClientList
}) => {
  return (
    <>
      <Title>
        <Trans>Trusted by</Trans>
      </Title>
      <Clients clientList={clientList} sx={{justifyContent: 'center'}} />
    </>
  );
};
